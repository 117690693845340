import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.is_show),callback:function ($$v) {_vm.is_show=$$v},expression:"is_show"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Crea un ticket di assistenza")])]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.catOpts,"label":"Scegli una categoria","outlined":"","required":""},model:{value:(_vm.form.ticket_category_id),callback:function ($$v) {_vm.$set(_vm.form, "ticket_category_id", $$v)},expression:"form.ticket_category_id"}})],1),(_vm.form.ticket_category_id)?_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"label":"Descrivici il problema che hai riscontrato","rows":"8","dense":"","outlined":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1):_vm._e(),_c(VCol,{staticClass:"text-right",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loading},on:{"click":_vm.closeModal}},[_vm._v(" Chiudi ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_vm._v(" Crea ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }