<template>
  <div>
    <v-dialog
      v-model="is_show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Crea un ticket di assistenza</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="create"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-select
                  v-model="form.ticket_category_id"
                  :items="catOpts"
                  label="Scegli una categoria"
                  outlined
                  required
                ></v-select>
              </v-col>
              <v-col
                v-if="form.ticket_category_id"
                cols="12"
              >
                <v-textarea
                  v-model="form.description"
                  label="Descrivici il problema che hai riscontrato"
                  rows="8"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="create"
                >
                  Crea
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'TicketCreate',
  computed: {
    ...mapGetters(['defaultDocumentsList', 'ticketCategories']),
    catOpts() {
      const opts = []
      this.ticketCategories.forEach(v => {
        opts.push({ text: v.name, value: v.id })
      })

      return opts
    },
  },
  props: {
    is_show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      loading: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu_date: false,
    }
  },
  methods: {
    async create() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.$api.createTicket(this.form)
          this.$store.dispatch('set_alert', { msg: 'Ticket creato con successo', type: 'success' })
          this.$emit('reload-data')
          this.closeModal()
        } catch (e) {
          this.loading = false
          this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    closeModal() {
      this.$refs.form.reset()
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
