import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right"},[_c(VBtn,{staticClass:"my-3 hidden-xs-only",attrs:{"color":"primary"},on:{"click":function($event){_vm.is_show=true}}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Crea Ticket ")],1),_c(VBtn,{staticClass:"my-3 hidden-sm-and-up",attrs:{"color":"primary","block":""},on:{"click":function($event){_vm.is_show=true}}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Crea Ticket ")],1)],1),_c(VCard,[_c(VCardTitle,[_vm._v(" Ticket di "+_vm._s(_vm.loggedUser.fullName)+" ")]),_c(VCardText,[[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Data Creazione")]),_c('th',[_vm._v("Creato da")]),_c('th',[_vm._v("Messaggi")]),_c('th',[_vm._v("Ultimo aggiornamento")]),_c('th',{staticClass:"text-center"},[_vm._v("Stato")]),_c('th')])]),_c('tbody',_vm._l((_vm.ticketsList),function(ticket,idx){return _c('tr',{key:'A'+idx},[_c('td',[_vm._v(_vm._s(ticket.createdAtFormatted))]),_c('td',[_vm._v(_vm._s(ticket.user.fullName))]),_c('td',[_vm._v(_vm._s(ticket.ticket_messages_count))]),_c('td',[_vm._v(_vm._s(ticket.latestMessage.createdAtFormatted))]),_c('td',{staticClass:"text-center"},[_c('generic-chip',{attrs:{"map-text":ticket.statusText,"map-green":"Aperto","map-red":"Chiuso"}})],1),_c('td',[_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary","dark":"","small":"","to":{name:'customerTicketDetail',params:{tid:ticket.id}}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-eye ")])],1)],1)])}),0)]},proxy:true}])})]],2),_c(VCardActions)],1),_c('create-ticket',{attrs:{"is_show":_vm.is_show},on:{"close":function($event){_vm.is_show=false},"reload-data":_vm.getData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }