<template>
    <v-chip
        :class="colorClass"
    >
        <span
            v-if="mapText===mapGreen"
        >
            {{ mapText }}
        </span>
        <span
            v-else-if="mapText===mapOrange"
        >
            {{ mapText }}
        </span>
        <span
            v-else-if="mapText===mapRed"
        >
            {{ mapText }}
        </span>
    </v-chip>
</template>

<script>
export default {
    name: "generic-chip",
    props: {
        mapText: {
            type : String,
            required: true
        },
        mapGreen: {
            type: String,
            default: 'enabled'
        },
        mapRed: {
            type: String,
            default: 'disabled'
        },
        mapOrange: {
            type: String,
            default: 'pending'
        }

    },
    computed: {
        colorClass() {
            let expReturn = 'ma-2 white--text '
            switch(this.mapText){
                case this.mapRed:
                    expReturn += 'red'
                    break;
                case this.mapOrange:
                    expReturn += 'orange'
                    break;
                case this.mapGreen:
                    expReturn += 'green'
                    break;
            }

            return expReturn
        }
    }
}
</script>
