<template>
    <div>
        <div
            class="text-right"
        >
            <v-btn
                color="primary"
                class="my-3 hidden-xs-only"
                @click="is_show=true"
            >
                <v-icon>mdi-plus</v-icon>
                Crea Ticket
            </v-btn>
            <v-btn
                color="primary"
                class="my-3 hidden-sm-and-up"
                @click="is_show=true"
                block
            >
                <v-icon>mdi-plus</v-icon>
                Crea Ticket
            </v-btn>
        </div>
        <v-card>
            <v-card-title>
                Ticket di {{ loggedUser.fullName }}
            </v-card-title>
            <v-card-text>
                <template>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th>Data Creazione</th>
                                <th>Creato da</th>
                                <th>Messaggi</th>
                                <th>Ultimo aggiornamento</th>
                                <th class="text-center">Stato</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(ticket,idx) in ticketsList"
                                :key="'A'+idx"
                            >
                                <td>{{ ticket.createdAtFormatted }}</td>
                                <td>{{ ticket.user.fullName }}</td>
                                <td>{{ ticket.ticket_messages_count }}</td>
                                <td>{{ ticket.latestMessage.createdAtFormatted }}</td>
                                <td class="text-center">
                                    <generic-chip :map-text="ticket.statusText" map-green="Aperto" map-red="Chiuso"></generic-chip>
                                </td>
                                <td>
                                    <v-btn
                                        class="ma-2"
                                        color="primary"
                                        dark
                                        small
                                        :to="{name:'customerTicketDetail',params:{tid:ticket.id}}"
                                    >
                                        <v-icon dark>
                                            mdi-eye
                                        </v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
            </v-card-text>
            <v-card-actions>

            </v-card-actions>
        </v-card>
        <create-ticket :is_show="is_show" @close="is_show=false" @reload-data="getData"></create-ticket>
    </div>
</template>

<script>

import CreateTicket from "@/components/modals/create-ticket"
import GenericChip from "@/components/chips/generic-chip"

import {mapGetters} from 'vuex'

export default {
    name: "tickets",
    components: {
        CreateTicket,
        GenericChip
    },
    data() {
        return {
            is_show: false,
            breadCrumb: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                },
                {
                    text: 'Ticket assistenza',
                    to: '/tickets',
                }
            ]
        }
    },
    mounted() {
        this.$store.dispatch('setBreadCrumbLevels',this.breadCrumb)
    },
    computed: {
        ...mapGetters(['loggedUser','ticketsList'])
    },
    methods: {
        async getData(){
            await this.$store.dispatch('getTickets')
        },
    }
}
</script>

<style scoped>

</style>